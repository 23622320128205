import { getLegacyQuoteRedirectUrl } from '~/utils/legacyQuote';

export default defineNuxtRouteMiddleware((to) => {
    if (getLegacyQuoteRedirectUrl(to.params.slug)) {
        throw createError({
            statusCode: 410,
            statusMessage: 'Gone',
        });
    }
});
