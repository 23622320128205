import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAnimatedImageContentStoryblok, LazyArticleCardContentStoryblok, LazyArticleStoryblok, LazyBlogArticleGridStoryblok, LazyCollapseCardGroupStoryblok, LazyContactFormStoryblok, LazyCreditApplicationFormStoryblok, LazyCtaSectionStoryblok, LazyDesignLabPickYourProductStoryblok, LazyDesignResourceGalleryStoryblok, LazyDesignerDetailStoryblok, LazyDifferentiatorGridStoryblok, LazyExampleBlok, LazyFaqCategoryViewStoryblok, LazyHeroStoryblok, LazyHowItWorksShowcaseStoryblok, LazyImageContentStoryblok, LazyImageGridContentStoryblok, LazyInspirationGalleryStoryblok, LazyJobStoryblok, LazyLeadTimesViewStoryblok, LazyPageIntroStoryblok, LazyProcessSelectStoryblok, LazyProductAnatomyStoryblok, LazyProductCardContentStoryblok, LazyProductCarouselBlock, LazyProductFilterStoryblok, LazyProductFinderBlock, LazyProductPackagingShowcaseStoryblok, LazyProductResourceGalleryStoryblok, LazyProductStoryblok, LazySamplePackSelectorStoryblok, LazyStoryblokBlock, LazyTableOfContentsViewStoryblok, LazyTemplateGalleryStoryblok, LazyTestimonialContentSliderStoryblok, LazyTradeShowGridStoryblok, LazyTrustpilotTestimonialsGridStoryblok, LazyVideoContentStoryblok, LazyBlockBottomAngle } from '#components'
const lazyGlobalComponents = [
  ["AnimatedImageContentStoryblok", LazyAnimatedImageContentStoryblok],
["ArticleCardContentStoryblok", LazyArticleCardContentStoryblok],
["ArticleStoryblok", LazyArticleStoryblok],
["BlogArticleGridStoryblok", LazyBlogArticleGridStoryblok],
["CollapseCardGroupStoryblok", LazyCollapseCardGroupStoryblok],
["ContactFormStoryblok", LazyContactFormStoryblok],
["CreditApplicationFormStoryblok", LazyCreditApplicationFormStoryblok],
["CtaSectionStoryblok", LazyCtaSectionStoryblok],
["DesignLabPickYourProductStoryblok", LazyDesignLabPickYourProductStoryblok],
["DesignResourceGalleryStoryblok", LazyDesignResourceGalleryStoryblok],
["DesignerDetailStoryblok", LazyDesignerDetailStoryblok],
["DifferentiatorGridStoryblok", LazyDifferentiatorGridStoryblok],
["ExampleBlok", LazyExampleBlok],
["FaqCategoryViewStoryblok", LazyFaqCategoryViewStoryblok],
["HeroStoryblok", LazyHeroStoryblok],
["HowItWorksShowcaseStoryblok", LazyHowItWorksShowcaseStoryblok],
["ImageContentStoryblok", LazyImageContentStoryblok],
["ImageGridContentStoryblok", LazyImageGridContentStoryblok],
["InspirationGalleryStoryblok", LazyInspirationGalleryStoryblok],
["JobStoryblok", LazyJobStoryblok],
["LeadTimesViewStoryblok", LazyLeadTimesViewStoryblok],
["PageIntroStoryblok", LazyPageIntroStoryblok],
["ProcessSelectStoryblok", LazyProcessSelectStoryblok],
["ProductAnatomyStoryblok", LazyProductAnatomyStoryblok],
["ProductCardContentStoryblok", LazyProductCardContentStoryblok],
["ProductCarouselBlock", LazyProductCarouselBlock],
["ProductFilterStoryblok", LazyProductFilterStoryblok],
["ProductFinderBlock", LazyProductFinderBlock],
["ProductPackagingShowcaseStoryblok", LazyProductPackagingShowcaseStoryblok],
["ProductResourceGalleryStoryblok", LazyProductResourceGalleryStoryblok],
["ProductStoryblok", LazyProductStoryblok],
["SamplePackSelectorStoryblok", LazySamplePackSelectorStoryblok],
["StoryblokBlock", LazyStoryblokBlock],
["TableOfContentsViewStoryblok", LazyTableOfContentsViewStoryblok],
["TemplateGalleryStoryblok", LazyTemplateGalleryStoryblok],
["TestimonialContentSliderStoryblok", LazyTestimonialContentSliderStoryblok],
["TradeShowGridStoryblok", LazyTradeShowGridStoryblok],
["TrustpilotTestimonialsGridStoryblok", LazyTrustpilotTestimonialsGridStoryblok],
["VideoContentStoryblok", LazyVideoContentStoryblok],
["BlockBottomAngle", LazyBlockBottomAngle],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
