const delay = 3000;

const loadDelayPending = ref(false);

const waitForLoadDelay = async () => {
    return new Promise((resolve) => {
        const checkDelay = () => {
            if (loadDelayPending.value) {
                setTimeout(checkDelay, 500);
            } else {
                resolve(true);
            }
        };

        checkDelay();
    });
};

const enqueueDelay = () => {
    loadDelayPending.value = true;

    setTimeout(() => {
        loadDelayPending.value = false;
    }, delay);
};

export default function () {
    return { enqueueDelay, waitForLoadDelay };
}
