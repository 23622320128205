import DOMPurify from 'isomorphic-dompurify';
import { FormErrors, PricingTier } from '~/types/miscellaneous';
import LinkType from '~/types/LinkType';
/* eslint-disable  @typescript-eslint/no-explicit-any */

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeWords = (string: string) => {
    const newString = string.split(' ');
    for (let i = 0, x = newString.length; i < x; i++) {
        newString[i] = newString[i][0].toUpperCase() + newString[i].substr(1);
    }
    return newString.join(' ');
};

const calculatePrice = (quantity: number, pricingTiers: PricingTier[]) => {
    let pricePerUnit = 0;

    pricingTiers.forEach((pricingTier) => {
        if (pricingTier.quantityBreak <= quantity) {
            pricePerUnit = pricingTier.price;
        }
    });

    return quantity * pricePerUnit;
};

const commaNumber = (value: number) => value.toLocaleString('en');

const formatCurrency = (value: number, withDollarSign = true) => {
    const formatted = value.toLocaleString('us-US', {
        style: 'currency',
        currency: 'USD',
    });

    return withDollarSign ? formatted : formatted.replace('$', '');
};

const formatDecimals = (value: number, decimals: number) => {
    return value.toFixed(decimals);
};

const formatPhone = (phone: string) => {
    if (phone.length === 10) {
        return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
    }

    return phone;
};

const getDecimalPlaces = (number: number) => {
    const numberAsString = number.toString();

    if (!numberAsString.includes('.')) {
        return 0;
    }

    const parts = numberAsString.split('.');
    return parts[1].length;
};

const getFormErrors = (responseData: any) => {
    const formErrors: FormErrors = {};

    if (responseData.message) {
        formErrors.form = responseData.message;
    }

    if (responseData.status === 'fail' && responseData.data) {
        Object.assign(formErrors, responseData.data);
    }

    return formErrors;
};

const getResponseErrorsAsHtml = (responseData: any) => {
    if (!responseData) {
        return null;
    }

    if (
        responseData.status === 'fail' &&
        responseData.data &&
        typeof responseData.data === 'object'
    ) {
        const errors: string[] = [];
        Object.values(responseData.data).forEach((error) => {
            if (Array.isArray(error)) {
                errors.push(...error);
            } else if ('string' === typeof error) {
                errors.push(error);
            }
        });
        return errors.join('<br>');
    }

    if (responseData.message) {
        return responseData.message;
    }

    return null;
};

const roundToNearestIncrement = (value: number, increment: number) => {
    // Due to floating point precision, there are rounding issues that occur
    // when the increment is a decimal number. To get around that, we multiply
    // both values by a correction factor that converts all values into integers
    // before performing the operation.

    const correctionFactor = 10 ** getDecimalPlaces(increment);

    const correctedValue = correctionFactor * value;
    const correctedIncrement = correctionFactor * increment;

    const result =
        Math.round(correctedValue / correctedIncrement) * correctedIncrement;

    return result / correctionFactor;
};

const sanitizeHtml = (unsafeHtml: string): string => {
    return DOMPurify.sanitize(unsafeHtml, { USE_PROFILES: { html: true } });
};

const getProductDisplayName = (productName: string, industry?: string) => {
    let displayName = productName;
    if (industry === undefined) {
        displayName = displayName.replace('[industry]', '');
        return displayName;
    }
    if (displayName.includes('[industry]')) {
        displayName = displayName.replace(
            '[industry]',
            capitalizeFirstLetter(industry),
        );
        return displayName;
    }

    const validProducts = ['bag', 'pouch', 'can'];
    const containsText = (text: string, array: string[]) => {
        return array.some((value) => text.includes(value));
    };

    const lastWord = displayName.substring(displayName.lastIndexOf(' ') + 1);
    const hasValidProduct = containsText(lastWord.toLowerCase(), validProducts);
    if (hasValidProduct) {
        const pos = displayName.length - lastWord.length;
        displayName = `${displayName.slice(0, pos)}${capitalizeFirstLetter(
            industry,
        )} ${displayName.slice(pos)}`;
        return displayName;
    }

    return displayName;
};

const scrollToId = (id: string) => {
    const node = document.getElementById(id);
    if (node) {
        node.scrollIntoView({ behavior: 'smooth' });
    }
};

const scrollToTop = (smooth = true) => {
    window.scrollTo({
        top: 0,
        behavior: smooth ? 'smooth' : 'instant',
    });
};

const linkFromStoryblokLinkBlock = (link: any): LinkType => {
    const formatted = {
        url: '#',
        label: '',
    };

    if (link?.label && link?.target) {
        const url = urlFromStoryblokLinkField(link.target);
        if (url) {
            formatted.label = link.label;
            formatted.url = url;
        }
    }

    return formatted;
};

const urlFromStoryblokLinkField = (link: any): string | null => {
    if (!link.story && !link.url) {
        return null;
    }

    if (link.story) {
        return link.story.url === '/' ? link.story.url : `/${link.story.url}`;
    } else {
        return link.url;
    }
};

const slugify = (str: string): string => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
};

const formattedDate = (date: string): string => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const thisDate = new Date(date);
    const day = thisDate.getDate();
    const month = thisDate.getMonth();
    const year = thisDate.getFullYear();
    return `${months[month]} ${day}, ${year}`;
};

const currentYear = (): number => {
    return new Date().getFullYear();
};

const compare = (
    a: string | number,
    b: string | number,
    descending = false,
) => {
    if (a < b) {
        return descending ? 1 : -1;
    }
    if (a > b) {
        return descending ? -1 : 1;
    }
    return 0;
};

const isValidHttpUrl = (urlValue: string) => {
    try {
        const url = new URL(urlValue);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
        return false;
    }
};

const pluralize = (num: number, word: string, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;

const stripTrailingSlash = (str: string) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
};

const stripTrailingSlashWithinAnchors = (str: string) => {
    return str.replaceAll(/(href=".*?)\/(")/g, '$1$2');
};

const titleCase = (s: string) =>
    s
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

export {
    capitalizeFirstLetter,
    capitalizeWords,
    calculatePrice,
    commaNumber,
    formatCurrency,
    formatDecimals,
    formatPhone,
    getDecimalPlaces,
    getFormErrors,
    getResponseErrorsAsHtml,
    roundToNearestIncrement,
    sanitizeHtml,
    getProductDisplayName,
    scrollToId,
    scrollToTop,
    linkFromStoryblokLinkBlock,
    urlFromStoryblokLinkField,
    slugify,
    formattedDate,
    currentYear,
    compare,
    isValidHttpUrl,
    pluralize,
    stripTrailingSlash,
    stripTrailingSlashWithinAnchors,
    titleCase,
};
