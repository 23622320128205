const useSegmentCode = (userId?: string) => {
    if (!segmentEnabled()) {
        return [];
    }
    const segmentKey = useRuntimeConfig().public.segmentKey;
    const anonymousId = getCookie('anonymous_id_v2') ?? '';

    return [
        {
            id: 'segmentCode',
            type: 'text/javascript',
            innerHTML:
                'var analytics = window.analytics = window.analytics || [];\nif (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {\nanalytics.invoked = !0;\nanalytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];\nanalytics.factory = function (e) {\nreturn function () {\nvar t = Array.prototype.slice.call(arguments);\nt.unshift(e);\nanalytics.push(t);\nreturn analytics\n}\n};\nfor (var e = 0; e < analytics.methods.length; e++) {\nvar t = analytics.methods[e];\nanalytics[t] = analytics.factory(t)\n}\nanalytics.load = function (e, t) {\nvar n = document.createElement("script");\nn.type = "text/javascript";\nn.async = !0;\nn.src = "https://cdn.segment.com/analytics.js/v1/" + e + "/analytics.min.js";\nvar a = document.getElementsByTagName("script")[0];\na.parentNode.insertBefore(n, a);\nanalytics._loadOptions = t\n};\nanalytics.SNIPPET_VERSION = "4.1.0";\nanalytics.load("' +
                segmentKey +
                '");\n' +
                (userId ? 'analytics.identify("' + userId + '");' : '') +
                '\nanalytics.setAnonymousId("' +
                anonymousId +
                '");\nanalytics.page();\n}',
        },
    ];
};

const useSegmentEvent = (event: string, category: string, label: string) => {
    if (!segmentEnabled()) {
        return [];
    }
    return [
        {
            type: 'text/javascript',
            innerHTML:
                'if (analytics) { analytics.track("' +
                event +
                '", { category: "' +
                category +
                '", label: "' +
                label +
                '" }); }',
        },
    ];
};

const useSegmentEventWithProperties = (
    event: string,
    category: string,
    label: string,
    properties: object,
) => {
    if (!segmentEnabled()) {
        return [];
    }
    const propertiesString = JSON.stringify(properties);
    const propertiesWithoutOpeningQuotes = propertiesString.replace(
        /"([^"]+)":/g,
        '$1:',
    );

    return [
        {
            type: 'text/javascript',
            innerHTML:
                'if (analytics) { analytics.track("' +
                event +
                '", { category: "' +
                category +
                '", label: "' +
                label +
                '", properties: ' +
                propertiesWithoutOpeningQuotes +
                '}); }',
        },
    ];
};

const useSegmentEventWithCustomPayload = (
    event: string,
    properties: object,
) => {
    if (!segmentEnabled()) {
        return [];
    }
    const propertiesString = JSON.stringify(properties);
    const propertiesWithoutOpeningQuotes = propertiesString.replace(
        /"([^"]+)":/g,
        '$1:',
    );

    return [
        {
            type: 'text/javascript',
            innerHTML:
                'if (analytics) { analytics.track("' +
                event +
                '", ' +
                propertiesWithoutOpeningQuotes +
                '); }',
        },
    ];
};

const segmentEnabled = () => {
    const segmentKey = useRuntimeConfig().public.segmentKey;
    return segmentKey !== undefined && segmentKey !== '';
};

const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export {
    useSegmentCode,
    useSegmentEvent,
    useSegmentEventWithProperties,
    useSegmentEventWithCustomPayload,
};
