import { ofetch } from 'ofetch';

export default defineNuxtPlugin(() => {
    if (
        (useRuntimeConfig().public.appEnv === 'local' ||
            useRuntimeConfig().public.appEnv === 'cypress') &&
        isCypress()
    ) {
        globalThis.$fetch = ofetch.create({
            onRequest({ options }) {
                options.headers = {
                    ...options.headers,
                    'x-environment': 'cypress',
                };
            },
            onRequestError({ error }) {
                console.error(error);
            },
        });
    }
});

const isCypress = () => {
    const cookie = useCookie('IS_CYPRESS').value;
    return cookie !== '' && cookie !== undefined && cookie !== null;
};
