<template>
    <div
        v-if="$slots.default"
        class="wysiwyg-content"
        :class="{ 'wysiwyg-compact': compact }"
    >
        <slot />
    </div>

    <div
        v-else-if="content"
        tabindex="0"
        class="wysiwyg-content"
        :class="{ 'wysiwyg-compact': compact }"
        v-html="sanitizeHtml(contentHtml)"
    ></div>
</template>

<script setup lang="ts">
import { stripTrailingSlashWithinAnchors } from '~/utils/helpers';

const props = withDefaults(
    defineProps<{
        content?: string | null;
        compact?: boolean;
    }>(),
    {
        content: '',
        compact: false,
    },
);

const contentHtml = computed(() => {
    let { content } = props;

    if (!content) {
        return '';
    }

    if (!content.includes('</p>')) {
        content = `<p>${content}</p>`;
    }
    
    content = content.replace(/src="([\w\W]+?)"/gm, 'src="\$1/m/fit-in/1086x0/filters:format(webp):quality(90)"');

    return stripTrailingSlashWithinAnchors(content);
});
</script>
