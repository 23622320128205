<template>
    <div
        v-show="visible"
        ref="tooltipRef"
        class="pointer-events-none absolute z-[100000000] rounded-md bg-white px-2 py-1 text-grind text-[12px] shadow-md"
        :style="{
            top: `${position.y}px`,
            left: `${position.x}px`,
        }"
    >
        {{ content }}
    </div>
</template>

<script setup lang="ts">
const tooltipRef = ref<HTMLElement | null>(null);
const tooltipState = reactive({
    visible: false,
    content: '',
    position: { x: 0, y: 0 },
});

let hideTimeout: ReturnType<typeof setTimeout> | null = null;

const showTooltip = async (content: string, el: HTMLElement) => {
    if (hideTimeout) {
        clearTimeout(hideTimeout);
        hideTimeout = null;
    }

    tooltipState.content = content;

    const hiddenElement = document.createElement('div');
    hiddenElement.style.position = 'absolute';
    hiddenElement.style.visibility = 'hidden';
    hiddenElement.style.top = '-9999px';
    hiddenElement.textContent = content;
    document.body.appendChild(hiddenElement);

    const { offsetWidth, offsetHeight } = hiddenElement;

    const bounds = el.getBoundingClientRect();
    tooltipState.position = {
        x: bounds.x - offsetWidth / 2,
        y: bounds.y - offsetHeight - 20,
    };

    tooltipState.visible = true;

    document.body.removeChild(hiddenElement);
};

const hideTooltip = async () => {
    if (hideTimeout) {
        clearTimeout(hideTimeout);
    }

    hideTimeout = setTimeout(() => {
        tooltipState.visible = false;
    }, 600);
};

const { visible, content, position } = toRefs(tooltipState);

defineExpose({
    showTooltip,
    hideTooltip,
});
</script>
