const getLegacyQuoteRedirectUrl = (
    routeSlug: string | string[],
): string | null => {
    if (
        Array.isArray(routeSlug) &&
        routeSlug.length === 3 &&
        routeSlug[0] === 'get-quote' &&
        routeSlug[1] === 'project'
    ) {
        return `/get-quote#project=${routeSlug[2]}`;
    }

    return null;
};

export { getLegacyQuoteRedirectUrl };
