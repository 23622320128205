<template>
    <TransitionRoot :show="isOpen" as="template">
        <Dialog class="relative z-[999999]" @close="onClose">
            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-grind/60"></div>
            </TransitionChild>

            <div class="fixed bottom-0 right-0 top-0 flex w-full max-w-[500px]">
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="translate-x-full"
                    enter-to="translate-x-0"
                    leave="duration-200 ease-in"
                    leave-from="translate-x-0"
                    leave-to="translate-x-full"
                    class="flex w-full bg-white shadow-card"
                >
                    <DialogPanel
                        class="w-full p-6"
                        data-cy="add-to-cart-flyout"
                    >
                        <button
                            class="absolute right-6 top-8 transition-colors hover:text-brew focus:outline-none"
                            data-cy="add-to-cart-flyout-close"
                            @click.prevent="onClose"
                        >
                            <Icon
                                name="close"
                                class="text-[24px] sm:text-[30px]"
                            />
                        </button>

                        <DialogTitle class="mr-10" as="h2">
                            In My Cart
                        </DialogTitle>

                        <div
                            class="my-4 flex flex-col divide-y divide-grind-200"
                        >
                            <div
                                v-for="addedCartItem in addedCartItems"
                                :key="addedCartItem.productName"
                                class="flex items-center gap-6 py-8"
                            >
                                <img
                                    v-if="addedCartItem.image"
                                    :src="addedCartItem.image.src"
                                    :alt="addedCartItem.image.alt || undefined"
                                    class="h-24 w-24 rounded-lg object-contain p-1 shadow-card"
                                />

                                <div>
                                    <div class="font-bold">
                                        {{ addedCartItem.productName }}
                                    </div>
                                    <div class="mt-2">
                                        has been added to your cart!
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col gap-5">
                            <button class="btn-primary" @click="goToCart">
                                View Cart
                            </button>
                            <button class="btn-secondary" @click="goToCheckout">
                                Checkout
                            </button>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import { Image } from '~/types/miscellaneous';
import { Buyable } from '~/types/Buyable';

type AddedCartItem = {
    productName: string;
    image: Image | null;
};

const state = reactive<{
    isOpen: boolean;
    addedCartItems: AddedCartItem[];
}>({
    isOpen: false,
    addedCartItems: [],
});

const open = (addedCartItems: AddedCartItem[] | AddedCartItem) => {
    state.isOpen = true;
    state.addedCartItems = Array.isArray(addedCartItems)
        ? addedCartItems
        : [addedCartItems];
};

const onClose = () => {
    state.isOpen = false;
};
const { isOpen, addedCartItems } = toRefs(state);

if (!useNuxtApp().$cartFlyout) {
    useNuxtApp().provide('cartFlyout', (buyables: Buyable[]) => {
        open(buyables);
    });
}

const goToCart = () => {
    state.isOpen = false;
    navigateTo('/cart');
};

const goToCheckout = () => {
    state.isOpen = false;
    navigateTo('/cart/checkout');
};

defineExpose({ open });
</script>
