import revive_payload_client_4sVQNw7RlN from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/buddy/roastar-combined/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/buddy/roastar-combined/nuxt/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_BqLkNUef68 from "/buddy/roastar-combined/nuxt/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/buddy/roastar-combined/nuxt/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/buddy/roastar-combined/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/buddy/roastar-combined/nuxt/plugins/auth.ts";
import click_away_client_jv8YTUZ4Iw from "/buddy/roastar-combined/nuxt/plugins/click-away.client.ts";
import error_handler_kEP5FliEXj from "/buddy/roastar-combined/nuxt/plugins/error-handler.ts";
import fetch_interceptor_f130thitDi from "/buddy/roastar-combined/nuxt/plugins/fetch-interceptor.ts";
import tooltip_client_Mj8x1saCmV from "/buddy/roastar-combined/nuxt/plugins/tooltip.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_BqLkNUef68,
  plugin_KlVwwuJRCL,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  click_away_client_jv8YTUZ4Iw,
  error_handler_kEP5FliEXj,
  fetch_interceptor_f130thitDi,
  tooltip_client_Mj8x1saCmV
]