import { ComponentPublicInstance, createApp } from 'vue';
import Tooltip from '~/components/ArtboardTooltip.vue';

export default defineNuxtPlugin(() => {
    const tooltipApp = createApp(Tooltip);
    const tooltipDiv = document.createElement('div');
    document.body.appendChild(tooltipDiv);

    const tooltipInstance = tooltipApp.mount(
        tooltipDiv,
    ) as ComponentPublicInstance<{
        showTooltip: (content: string, el: HTMLElement) => void;
        hideTooltip: () => void;
    }>;

    return {
        provide: {
            showTooltip: (content: string, el: HTMLElement) =>
                tooltipInstance.showTooltip(content, el),
            hideTooltip: () => tooltipInstance.hideTooltip(),
        },
    };
});
