<template>
    <Modal ref="modalRef" :allow-close="false">
        <template v-if="title" #title>{{ title }}</template>

        <WysiwygContent :content="contentHtml" :compact="true" />

        <template #actions>
            <button
                v-if="cancelButtonLabel"
                class="btn-secondary"
                :disabled="loading"
                @click="onCancel"
            >
                {{ cancelButtonLabel }}
            </button>

            <LoadingButton
                class="btn-primary"
                :loading="loading"
                @click="onConfirm"
            >
                {{ confirmButtonLabel }}
            </LoadingButton>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';
import { AlertModalButtonsInput } from '~/utils/alertModals';

const modalRef = ref<InstanceType<typeof Modal> | null>(null);

const state = reactive<{
    title: string | null;
    message: string;
    buttons: AlertModalButtonsInput | null;
    loading: boolean;
}>({
    title: null,
    message: '',
    buttons: null,
    loading: false,
});

const contentHtml = computed(() => {
    if (!state.message) {
        return '';
    }

    return state.message.includes('<')
        ? state.message
        : '<p>' + state.message + '</p>';
});

const cancelButtonLabel = computed(() => {
    if (true === state.buttons?.cancel) {
        return 'Cancel';
    }

    if ('string' === typeof state.buttons?.cancel) {
        return state.buttons.cancel;
    }

    if (state.buttons?.cancel?.label) {
        return state.buttons.cancel.label;
    }

    return null;
});

const onCancel = async () => {
    let onClick: () => Promise<void> | void = () => {
        /* Default onClick callback */
    };
    if (
        'object' === typeof state.buttons?.cancel &&
        'function' === typeof state.buttons.cancel.onClick
    ) {
        onClick = state.buttons.cancel.onClick;
    }

    if ('AsyncFunction' === onClick.constructor.name) {
        await onClick();
    } else {
        onClick();
    }

    modalRef.value?.close();
};

const confirmButtonLabel = computed(() => {
    if ('string' === typeof state.buttons?.confirm) {
        return state.buttons.confirm;
    }

    if (state.buttons?.confirm?.label) {
        return state.buttons.confirm.label;
    }

    return 'OK';
});

const onConfirm = async () => {
    let onClick: () => Promise<void> | void = () => {
        // Default onClick handler
    };
    if (
        'object' === typeof state.buttons?.confirm &&
        'function' === typeof state.buttons.confirm.onClick
    ) {
        onClick = state.buttons.confirm.onClick;
    }

    if ('AsyncFunction' === onClick.constructor.name) {
        state.loading = true;
        await onClick();
        setTimeout(() => {
            state.loading = false;
        }, 250);
    } else {
        onClick();
    }

    modalRef.value?.close();
};

if (!useNuxtApp().$alert) {
    useNuxtApp().provide(
        'alert',
        (
            title: string | null,
            message: string,
            buttons: AlertModalButtonsInput | null,
        ) => {
            state.title = title;
            state.message = message;
            state.buttons = buttons;

            modalRef.value?.open();
        },
    );
}

const { title, loading } = toRefs(state);
</script>
