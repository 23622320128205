<template>
    <div>
        <div class="flex justify-center p-5 shadow-md">
            <a href="/">
                <img
                    alt="Roastar Logo"
                    src="~/assets/images/roastar_logo.svg"
                    class="w-full max-w-[250px]"
                />
            </a>
        </div>

        <div class="container my-20 text-center">
            <div v-if="browserRedirectUrl">
                Redirecting you to
                <a :href="baseUrl + browserRedirectUrl">
                    {{ baseUrl + browserRedirectUrl }}
                </a>
            </div>

            <template v-else>
                <h1>{{ headingText }}</h1>

                <p class="mt-12">
                    <a href="/" class="btn-primary">Go Home</a>
                </p>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getLegacyQuoteRedirectUrl } from '~/utils/legacyQuote';

const error = useError();
const routeParams = useRoute().params;
const { baseUrl } = useRuntimeConfig().public;

let browserRedirectUrl: string | null = null;

const legacyQuoteRedirectUrl = getLegacyQuoteRedirectUrl(routeParams.slug);
if (legacyQuoteRedirectUrl) {
    browserRedirectUrl = legacyQuoteRedirectUrl;
}

const headingText = computed(() => {
    return error?.statusCode === 404 ? '404 - Page Not Found' : 'Error';
});

onBeforeMount(() => {
    if (browserRedirectUrl) {
        navigateTo(browserRedirectUrl);
    }
});
</script>
